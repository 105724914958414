import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessView.vue')
  },
  {
    path: '/failed',
    name: 'failed',
    component: () => import(/* webpackChunkName: "about" */ '../views/FiailedView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminLogin.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/DashboardView.vue')
  },
  {
    path: '/reserve',
    name: 'reserve',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ReserveView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/SettingsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
      next('/login');
  } else {
      next();
  }
});



export default router
