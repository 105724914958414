<template>
    <div class="home" v-if="dataLoaded">
        <div class="preload" v-if="preload">
            <div class="loader"></div>
        </div>
        <div class="welcome rtl">
            <h2>{{ firstTitle }}</h2>
            <div>
                <p>{{ firstDesc }}</p>
            </div>
        </div>

        <div class="card rtl">
            <h4 style="display: flex; align-items: center">
                <img
                    src="../assets/images/document-text.svg"
                    alt=""
                    width="20"
                    height="20"
                    style="margin-left: 5px"
                />
                بياناتك الشخصية
            </h4>
            <p>قم بإدخال بياناتك الشخصية لإتمام حجزك !</p>
            <form action="" method="">
                <input
                    type="text"
                    placeholder="الأسم كامل"
                    v-model="theName"
                    autofocus
                    maxlength="20"
                />
                <input
                    type="tel"
                    placeholder="رقم الجوال الخاص بك"
                    v-model="thePhone"
                    maxlength="10"
                />
                <select v-model="theService" v-if="options.length >= 1">
                    <option value="">أختر نوع الخدمة</option>
                    <option
                        v-for="option in options"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </select>
                <div class="counter">
                    <h4>عدد الأشخاص</h4>
                    <div class="panel">
                        <div class="cccc" @click="decrement">
                            <img src="../assets/images/Vector.svg" alt="" />
                        </div>
                        <input
                            type="number"
                            :value="handleCounterDefault"
                            name=""
                            id=""
                            disabled
                        />
                        <div class="cccc" @click="incement">
                            <img src="../assets/images/Vector-1.svg" alt="" />
                        </div>
                    </div>
                </div>
                <strong class="error" v-if="hasError"
                    >عفو لديك مشكلة في رقم الجوال أو تحديد نوع االخدمة يرجى
                    إعادة المحاولة</strong
                >
            </form>
        </div>

        <div class="note rtl">
            <h4 style="font-size: 15px">
                <img
                    src="../assets/images/message-text.svg"
                    width="20"
                    height="20"
                    alt=""
                />
                ملاحظة !
            </h4>
            <p>يجب أن تصل قبل 10 دقائق من الموعد لتجنب الإلغاء</p>
        </div>

        <div style="height: 88px"></div>

        <button class="reserve" @click="reserve">إتمام الحجز</button>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data: function () {
        return {
            firstTitle: "",
            firstDesc: "",
            defaultCounter: 1,
            hasError: false,
            options: [],
            theName: "",
            thePhone: "",
            theService: "",
            preload: false,
            endTime: null,
            dataLoaded: false,
        };
    },
    async created() {
        try {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common["Authorization"] = token;
            const response = await axios.get(
                "https://vest-app.com/api/home?event_id=1"
            );
            if (response.status === 200) {
                this.dataLoaded = true;
            }
        } catch (error) {
            if (error.response.status === 422) {
                this.$router.push("/failed");
            }
        }

        try {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common["Authorization"] = token;
            const response = await axios.get(
                "https://vest-app.com/api/event/1"
            );
            if (response.status === 200) {
                this.firstTitle = response.data.welcome_msg_title;
                this.firstDesc = response.data.welcome_msg_description;
                this.firstDesc = response.data.welcome_msg_description;
                this.options = response.data.services.filter(
                    (option) => option.appearance == "1"
                );
                this.endTime = response.data.end_time;
            }
        } catch (error) {
            // console.error(error);
            console.log("Error");
        }
    },
    computed: {
        handleCounterDefault() {
            return "0" + this.defaultCounter;
        },
    },
    methods: {
        incement() {
            this.defaultCounter == 1 ? this.defaultCounter++ : "";
        },
        decrement() {
            this.defaultCounter > 1 ? this.defaultCounter-- : "";
        },
        reserve() {
            const regexPhoneNumber = /^((\0)5|0)[1-9](\d{2}){4}$/;
            if (this.theName.trim().length === 0) {
                // console.log('ER1');
                this.hasError = true;
            } else if (this.thePhone.match(regexPhoneNumber)) {
                this.hasError = false;
                this.theService == "" || this.theService == "null"
                    ? (this.theService = null)
                    : this.theService;
                var d = new Date();

                var datestring =
                    d.getFullYear() +
                    "-" +
                    ("0" + (d.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + d.getDate()).slice(-2);

                // if (this.endTime > datestring) {
                //   this.$router.push("/failed");
                // }
                if (this.theService === null) {
                    this.preload = true;
                    this.completeReserve(
                        this.theName,
                        this.thePhone,
                        this.defaultCounter,
                        1,
                        datestring
                    );
                } else {
                    this.preload = true;
                    this.completeReserveWithService(
                        this.theName,
                        this.thePhone,
                        this.defaultCounter,
                        this.theService,
                        1,
                        datestring
                    );
                }
            } else {
                // console.log('ER2');
                this.hasError = true;
            }
        },

        async completeReserveWithService(
            user_name,
            phone,
            reservations_number,
            service_id,
            event_id,
            date
        ) {
            try {
                const idList = [];
                const response = await axios.post(
                    "https://vest-app.com/api/reservations/user/create",
                    {
                        user_name: user_name,
                        phone: phone,
                        reservations_number: reservations_number,
                        service_id: service_id,
                        event_id: event_id,
                        date: date,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                        },
                    }
                );
                if (response.status === 200) {
                    this.preload = false;
                    for (let i = 0; i < response.data.length; i++) {
                        idList.push(response.data[i].reservation_num);
                    }
                    const myListString = JSON.stringify(idList);
                    localStorage.setItem("nums", myListString);

                    const event = response.data[0]?.event || null;
                    const instructions = {
                        title: event.instructions_title,
                        msg: event.instructions_msg,
                    };
                    localStorage.setItem(
                        "reservation_instructions",
                        JSON.stringify(instructions)
                    );

                    this.$router.push("/success");
                }
            } catch (error) {
                this.preload = false;
                this.$router.push("/failed");
                // console.log(error);
            }
        },

        async completeReserve(
            user_name,
            phone,
            reservations_number,
            event_id,
            date
        ) {
            try {
                const idList = [];
                const response = await axios.post(
                    "https://vest-app.com/api/reservations/user/create",
                    {
                        user_name: user_name,
                        phone: phone,
                        reservations_number: reservations_number,
                        event_id: event_id,
                        date: date,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                        },
                    }
                );
                if (response.status === 200) {
                    this.preload = false;
                    // console.log('created');

                    for (let i = 0; i < response.data.length; i++) {
                        idList.push(response.data[i].reservation_num);
                    }
                    const myListString = JSON.stringify(idList);
                    localStorage.setItem("nums", myListString);

                    const event = response.data[0]?.event || null;
                    const instructions = {
                        title: event.instructions_title,
                        msg: event.instructions_msg,
                    };
                    localStorage.setItem(
                        "reservation_instructions",
                        JSON.stringify(instructions)
                    );

                    this.$router.push("/success");
                }
            } catch (error) {
                this.preload = false;
                this.$router.push("/failed");
            }
        },
    },
};
</script>

<style scoped>
  .globe-image {
    margin-right: 8px;
    width: 23px;
  }
 .cccc {
    background-color: #f8fafc;
    width: 56px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
 }
</style>
